<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="isLoading">
          <v-card-title>{{ company.name }}</v-card-title>
          <v-card-text>
            <v-data-table v-if="companyTenants.length" :headers="headers" :items="companyTenants">
              <template v-slot:[`item.isAdmin`]="{ item }">
                {{ item.isAdmin ? 'Igen' : '' }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="20" color="black" v-on="on" @click="impersonateCompanyMember(item)"> mdi-eye </v-icon>
                  </template>
                  Impersonate company member
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GET_COMPANY } from '@/store/types'
import httpClient from '@/services/api.service'

export default {
  name: 'PageCompany',
  data() {
    return {
      company: {},
      companyTenants: [],
      isLoading: false,
      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Name',
          value: 'fullName'
        },
        {
          text: 'Url',
          value: 'redirectUrl'
        },
        {
          text: 'Admin',
          value: 'isAdmin'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ]
    }
  },
  computed: {
    companyId() {
      return this.$route.params.id
    }
  },
  methods: {
    async getCompany() {
      try {
        this.isLoading = true
        const company = await this.$store.dispatch(GET_COMPANY, { companyId: this.companyId })
        this.company = company
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.isLoading = false
      }
    },
    async getCompanyTenants() {
      try {
        const { data } = await httpClient.get(`companies/${this.companyId}`, 'tenants')
        this.companyTenants = data.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    impersonateCompanyMember(member) {
      window.open(`${window.location.protocol}//${member.redirectUrl}/bejelentkezes/?hash=${member.token}`, '_blank')
    }
  },
  async created() {
    await this.getCompany()
    await this.getCompanyTenants()
  }
}
</script>
